import React from 'react';
import { Icons } from '../../components';
import css from './CategoryPage.module.css';
import { convertMarkdownToReact } from '../../util/markdownHelpers';

function OtherInformation(props) {
  const { currentLocation, translations } = props;
  return (
    <div className={css.popularCategorySec}>
      <h2>About {translations?.title}</h2>
      <div className={css.otherinfoBlock}>
        <div className={css.otherinfoLeftBlock}>
          <Icons name="overview" />
          <h3>Overview</h3>
        </div>
        <div className={css.otherinfoRightBlock}>
          <p>
            {translations?.summaryDescription
              ? convertMarkdownToReact(translations?.summaryDescription)
              : null}
          </p>
        </div>
      </div>
      <div className={css.otherinfoBlock}>
        <div className={css.otherinfoLeftBlock}>
          <Icons name="map" />
          <h3>Where to go</h3>
        </div>
        <div className={css.otherinfoRightBlock}>
          {translations?.description ? convertMarkdownToReact(translations?.description) : null}
        </div>
      </div>
    </div>
  );
}

export default OtherInformation;
