import { createSlug } from '../../util/urlHelpers';
import { draftSlug } from '../../routing/routeConfiguration';

export const getRedirectParams = (data) => {
  const location = data[0];

  if (location?.country && location?.state && location?.title) {
    return {
      country: createSlug(location.country.name || draftSlug),
      state: createSlug(location.state.name || draftSlug),
      city: createSlug(location.title || draftSlug),
      page: 'CityCategoryPage',
    };
  } else if (location?.country && location?.title) {
    return {
      country: createSlug(location.country.name || draftSlug),
      state: createSlug(location.title || draftSlug),
      page: 'StateCategoryPage',
    };
  } else if (location?.title) {
    return {
      country: createSlug(location.title || draftSlug),
      page: 'CountryCategoryPage',
    };
  } else {
    return {};
  }
};
