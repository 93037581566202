import React, { useState, useEffect } from 'react';
import { AutoSuggestion, Button } from '../../components';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';
import { getRedirectParams } from './categoryHelpers';
import { useDispatch, useSelector } from 'react-redux';
import SimpleSearch from '../../components/SimpleSearch/SimpleSearch';
import { fetchSearchData } from '../../ducks/searchPlace.duck';
import { setLocationData } from '../../ducks/singleLocationData.duck';

import css from './CategoryPage.module.css';

function SectionHero({ history, intl, translations, renderBreadcrumbs }) {
  const [searchText, setSearchText] = useState('');
  const [redirect, setRedirect] = useState(false);
  const { data, loading } = useSelector(state => state?.searchPlace);
  const dispatch = useDispatch();

  useEffect(() => {
    if (redirect && data?.length > 0) {
      const { country, state, city, page } = getRedirectParams(data);

      if (country && state && city && page) {
        // Redirect to the appropriate page
        history.push(
          createResourceLocatorString(page, routeConfiguration(), { country, state, city }, {})
        );

        // Reset redirect state
        setRedirect(false);
      }
    }
  }, [data, redirect, history]);

  const handleSearch = async () => {
    if (searchText) {
      // Dispatch the search query or action when the button is clicked
      await dispatch(fetchSearchData({ searchData: searchText }));

      // Set redirect state to true after fetching data
      setRedirect(true);
    }
  };

  const handleSearchChange = value => {
    setSearchText(value);
  };

  return (
    <div className={css.categoryDetailPage}>
      {renderBreadcrumbs}
      <h2>{translations?.title}</h2>
      <p>
        {intl.formatMessage(
          { id: 'CategoryPage.SectionHero.title' },
          { location: translations?.title }
        )}
      </p>
      <div className={css.categorySearch}>
        <SimpleSearch
          onSearchChange={handleSearchChange}
          placeholder={intl.formatMessage({
            id: 'CategoryPage.SectionHero.inputPlaceholder'
          })}
        />
        {loading ? (
          <Button className={css.searchBtnDisabled} type="button">
            {intl.formatMessage({ id: 'CategoryPage.SectionHero.searchKey' })}
          </Button>
        ) : (
          <Button className={css.searchBtn} type="button" onClick={handleSearch}>
            {intl.formatMessage({ id: 'CategoryPage.SectionHero.searchKey' })}
          </Button>
        )}
      </div>
    </div>
  );
}

export default SectionHero;
