import React, { useState } from 'react';
import css from './SimpleSearch.module.css';

const SimpleSearch = ({ onSearchChange, placeholder }) => {
  const [searchValue, setSearchValue] = useState("");

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);
    onSearchChange(value); // Send the typed value to the parent but don't trigger the search yet
  };

  return (
    <input
      type="text"
      className={css.searchInput}
      value={searchValue}
      onChange={handleInputChange}
      placeholder={placeholder}
    />
  );
};

export default SimpleSearch;
